const data = {
  editorial: [
    {
      path: "editorial/editorial(1).jpg",
    },
    {
      path: "editorial/editorial(2).jpg",
    },
    {
      path: "editorial/editorial(3).jpg",
    },
    {
      path: "editorial/editorial(4).jpg",
    },
    {
      path: "editorial/editorial(5).jpg",
    },
    {
      path: "editorial/editorial(6).jpg",
    },
    {
      path: "editorial/editorial(7).jpg",
    },
    {
      path: "editorial/editorial(8).jpg",
    },
    {
      path: "editorial/editorial(9).jpg",
    },
    {
      path: "editorial/editorial(10).jpg",
    },
    {
      path: "editorial/editorial(11).jpg",
    },
    {
      path: "editorial/editorial(12).jpg",
    },
    {
      path: "editorial/editorial(13).jpg",
    },
    {
      path: "editorial/editorial(14).jpg",
    },
    {
      path: "editorial/editorial(15).jpg",
    },
    {
      path: "editorial/editorial(16).jpg",
    },
    {
      path: "editorial/editorial(17).jpg",
    },
    {
      path: "editorial/editorial(18).jpg",
    },
    {
      path: "editorial/editorial(19).jpg",
    },
    {
      path: "editorial/editorial(20).jpg",
    },
    {
      path: "editorial/editorial(21).jpg",
    },
    {
      path: "editorial/editorial(22).jpg",
    },
    {
      path: "editorial/editorial(23).jpg",
    },
    {
      path: "editorial/editorial(24).jpg",
    },
    {
      path: "editorial/editorial(25).jpg",
    },
    {
      path: "editorial/editorial(26).jpg",
    },
    {
      path: "editorial/editorial(27).jpg",
    },
    {
      path: "editorial/editorial(28).jpg",
    },
    {
      path: "editorial/editorial(29).jpg",
    },
    {
      path: "editorial/editorial(30).jpg",
    },
    {
      path: "editorial/editorial(31).jpg",
    },
    {
      path: "editorial/editorial(32).jpg",
    },
    {
      path: "editorial/editorial(33).jpg",
    },
    {
      path: "editorial/editorial(34).jpg",
    },
    {
      path: "editorial/editorial(35).jpg",
    },
    {
      path: "editorial/editorial(36).jpg",
    },
    {
      path: "editorial/editorial(37).jpg",
    },
    {
      path: "editorial/editorial(38).jpg",
    },
    {
      path: "editorial/editorial(39).jpg",
    },
    {
      path: "editorial/editorial(40).jpg",
    },
    {
      path: "editorial/editorial(41).jpg",
    },
    {
      path: "editorial/editorial(42).jpg",
    },
    {
      path: "editorial/editorial(43).jpg",
    },
    {
      path: "editorial/editorial(44).jpg",
    },
    {
      path: "editorial/editorial(45).jpg",
    },
    {
      path: "editorial/editorial(46).jpg",
    },
    {
      path: "editorial/editorial(47).jpg",
    },
    {
      path: "editorial/editorial(48).jpg",
    },
    {
      path: "editorial/editorial(49).jpg",
    },
    {
      path: "editorial/editorial(50).jpg",
    },
    {
      path: "editorial/editorial(51).jpg",
    },
    {
      path: "editorial/editorial(52).jpg",
    },
    {
      path: "editorial/editorial(53).jpg",
    },
    {
      path: "editorial/editorial(54).jpg",
    },
    {
      path: "editorial/editorial(55).jpg",
    },
    {
      path: "editorial/editorial(56).jpg",
    },
    {
      path: "editorial/editorial(57).jpg",
    },
    {
      path: "editorial/editorial(58).jpg",
    },
    {
      path: "editorial/editorial(59).jpg",
    },
    {
      path: "editorial/editorial(60).jpg",
    },
    {
      path: "editorial/editorial(61).jpg",
    },
    {
      path: "editorial/editorial(62).jpg",
    },
    {
      path: "editorial/editorial(63).jpg",
    },
    {
      path: "editorial/editorial(64).jpg",
    },
    {
      path: "editorial/editorial(65).jpg",
    },
    {
      path: "editorial/editorial(66).jpg",
    },
    {
      path: "editorial/editorial(67).jpg",
    },
    {
      path: "editorial/editorial(68).jpg",
    },
    {
      path: "editorial/editorial(69).jpg",
    },
    {
      path: "editorial/editorial(70).jpg",
    },
    {
      path: "editorial/editorial(71).jpg",
    },
    {
      path: "editorial/editorial(72).jpg",
    },
    {
      path: "editorial/editorial(73).jpg",
    },
    {
      path: "editorial/editorial(74).jpg",
    },
    {
      path: "editorial/editorial(75).jpg",
    },
    {
      path: "editorial/editorial(76).jpg",
    },
    {
      path: "editorial/editorial(77).jpg",
    },
    {
      path: "editorial/editorial(78).jpg",
    },
    {
      path: "editorial/editorial(79).jpg",
    },
    {
      path: "editorial/editorial(80).jpg",
    },
    {
      path: "editorial/editorial(81).jpg",
    },
    {
      path: "editorial/editorial(82).jpg",
    },
    {
      path: "editorial/editorial(83).jpg",
    },
    {
      path: "editorial/editorial(84).jpg",
    },
    {
      path: "editorial/editorial(85).jpg",
    },
    {
      path: "editorial/editorial(86).jpg",
    },
    {
      path: "editorial/editorial(87).jpg",
    },
    {
      path: "editorial/editorial(88).jpg",
    },
    {
      path: "editorial/editorial(89).jpg",
    },
    {
      path: "editorial/editorial(90).jpg",
    },
  ],
  product: [
    {
      path: "product/Foto-1.jpg",
    },
    {
      path: "product/Foto-2.jpg",
    },
    {
      path: "product/Foto-3.jpg",
    },
    {
      path: "product/Foto-4.jpg",
    },
    {
      path: "product/Foto-5.jpg",
    },
    {
      path: "product/Foto-6.jpg",
    },
    {
      path: "product/Foto-7.jpg",
    },
    {
      path: "product/Foto-8.jpg",
    },
    {
      path: "product/Foto-9.jpg",
    },
    {
      path: "product/Foto-10.jpg",
    },
    {
      path: "product/Foto-11.jpg",
    },
    {
      path: "product/Foto-12.jpg",
    },
    {
      path: "product/Foto-13.jpg",
    },
    {
      path: "product/Foto-14.jpg",
    },
    {
      path: "product/Foto-15.jpg",
    },
    {
      path: "product/Foto-16.jpg",
    },
    {
      path: "product/Foto-17.jpg",
    },
    {
      path: "product/Foto-18.jpg",
    },
    {
      path: "product/Foto-19.jpg",
    },
    {
      path: "product/Foto-20.jpg",
    },
    {
      path: "product/Foto-21.jpg",
    },
    {
      path: "product/Foto-22.jpg",
    },
    {
      path: "product/Foto-23.jpg",
    },
    {
      path: "product/Foto-24.jpg",
    },
  ],
  landscapes: [
    {
      path: "landscapes/landscapes(1).jpg",
    },
    {
      path: "landscapes/landscapes(2).jpg",
    },
    {
      path: "landscapes/landscapes(3).jpg",
    },
    {
      path: "landscapes/landscapes(4).jpg",
    },
    {
      path: "landscapes/landscapes(5).jpg",
    },
    {
      path: "landscapes/landscapes(6).jpg",
    },
    {
      path: "landscapes/landscapes(7).jpg",
    },
    {
      path: "landscapes/landscapes(8).jpg",
    },
    {
      path: "landscapes/landscapes(9).jpg",
    },
    {
      path: "landscapes/landscapes(10).jpg",
    },
    {
      path: "landscapes/landscapes(11).jpg",
    },
    {
      path: "landscapes/landscapes(12).jpg",
    },
    {
      path: "landscapes/landscapes(13).jpg",
    },
  ],
  misc: [
    {
      path: "misc/misc(1).jpg",
    },
    {
      path: "misc/misc(2).jpg",
    },
    {
      path: "misc/misc(3).jpg",
    },
    {
      path: "misc/misc(4).jpg",
    },
    {
      path: "misc/misc(5).jpg",
    },
    {
      path: "misc/misc(6).jpg",
    },
    {
      path: "misc/misc(7).jpg",
    },
    {
      path: "misc/misc(8).jpg",
    },
    {
      path: "misc/misc(9).jpg",
    },
    {
      path: "misc/misc(10).jpg",
    },
    {
      path: "misc/misc(11).jpg",
    },
    {
      path: "misc/misc(12).jpg",
    },
    {
      path: "misc/misc(13).jpg",
    },
    {
      path: "misc/misc(14).jpg",
    },
    {
      path: "misc/misc(15).jpg",
    },
    {
      path: "misc/misc(16).jpg",
    },
    {
      path: "misc/misc(17).jpg",
    },
    {
      path: "misc/misc(18).jpg",
    },
    {
      path: "misc/misc(19).jpg",
    },
    {
      path: "misc/misc(20).jpg",
    },
    {
      path: "misc/misc(21).jpg",
    },
    {
      path: "misc/misc(22).jpg",
    },
    {
      path: "misc/misc(23).jpg",
    },
    {
      path: "misc/misc(24).jpg",
    },
    {
      path: "misc/misc(25).jpg",
    },
    {
      path: "misc/misc(26).jpg",
    },
    {
      path: "misc/misc(27).jpg",
    },
    {
      path: "misc/misc(28).jpg",
    },
    {
      path: "misc/misc(29).jpg",
    },
    {
      path: "misc/misc(30).jpg",
    },
    {
      path: "misc/misc(31).jpg",
    },
    {
      path: "misc/misc(32).jpg",
    },
    {
      path: "misc/misc(33).jpg",
    },
    {
      path: "misc/misc(34).jpg",
    },
    {
      path: "misc/misc(35).jpg",
    },
    {
      path: "misc/misc(36).jpg",
    },
    {
      path: "misc/misc(37).jpg",
    },
    {
      path: "misc/misc(38).jpg",
    },
    {
      path: "misc/misc(39).jpg",
    },
    {
      path: "misc/misc(40).jpg",
    },
  ],
  film: [
    {
      path: "film/OnesoundPoltamento.jpg",
      url: "https://www.youtube.com/watch?v=6fVQNlAWd1Q",
      src: "https://www.youtube.com/embed/6fVQNlAWd1Q",
    },
    {
      path: "film/Vencejo.jpg",
      url: "https://www.youtube.com/watch?v=_RHv_-xgVv4",
      src: "https://www.youtube.com/embed/_RHv_-xgVv4",
    },
    {
      path: "film/Julia-Funai-(Nakauchi)---El-Espacio-entre-las-Capas.jpg",
      url: "https://www.youtube.com/watch?v=Us8iO8Teqr4",
      src: "https://www.youtube.com/embed/Us8iO8Teqr4",
    },
    {
      path: "film/Radio-AM---A-Pocketful-of-Songs.jpg",
      url: "https://www.youtube.com/watch?v=hwxB4NKvYK0",
      src: "https://www.youtube.com/embed/hwxB4NKvYK0",
    },
    {
      path: "film/Burden-Rage---Crowned-by-Pestilence.jpg",
      url: "https://www.youtube.com/watch?v=i3Up26NCk9s",
      src: "https://www.youtube.com/embed/i3Up26NCk9s",
    },
    {
      path: "film/Del-Mar-a-Marte---Me-Voy.jpg",
      url: "https://www.youtube.com/watch?v=R2pw9_6eZmA",
      src: "https://www.youtube.com/embed/R2pw9_6eZmA",
    },
    {
      path: "film/El-Otro-Extremo---Dentro-de-la-Mente.jpg",
      url: "https://www.youtube.com/watch?v=5J6_3A3l_lo",
      src: "https://www.youtube.com/embed/5J6_3A3l_lo",
    },
    {
      path: "film/The-Raul---6-de-Agosto.jpg",
      url: "https://www.youtube.com/watch?v=tmvhUrvgbHc",
      src: "https://www.youtube.com/embed/tmvhUrvgbHc",
    },
    {
      path: "film/Negro-Rojo-Amarillo.jpg",
      url: "https://www.youtube.com/watch?v=OcJimGrgK3I",
      src: "https://www.youtube.com/embed/OcJimGrgK3I",
    },
    {
      path: "film/Pura-Oxitocina.jpg",
      url: "https://www.youtube.com/watch?v=yF65eqVsAG4",
      src: "https://www.youtube.com/embed/yF65eqVsAG4",
    },
    {
      path: "film/Quien-Escribe-con-Sangre.jpg",
      url: "https://www.youtube.com/watch?v=l6JD1XUMk9M",
      src: "https://www.youtube.com/embed/l6JD1XUMk9M",
    },
    {
      path: "film/SAM.jpg",
      url: "https://www.youtube.com/watch?v=JPLtEEfXz1U",
      src: "https://www.youtube.com/embed/JPLtEEfXz1U",
    },
  ],
};

export default data;
