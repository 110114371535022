import $ from "jquery";
import data from "../../data/data.js";

$(() => {
  const Categories = {
    Editorial: "editorial",
    Product: "product",
    Landscapes: "landscapes",
    Misc: "misc",
    Film: "film",
  };

  const breakpointWidth = 1024;
  let imagesNamesList = {};
  const imagesPath = "/images/gallery/";
  const imagesLoaded = [];
  const categoriesInitialized = [];
  const defaultCategory = Categories.Product;
  const imagesCounterByCategory = {
    editorial: 0,
    product: 0,
    landscapes: 0,
    misc: 0,
    film: 0,
    increaseCounter: function (category) {
      this[category]++;

      if (isImagesCounterByCategoryMax(category))
        insertImagesToGallery($(".gallery"), category, imagesLoaded);
    },
  };

  function initialize() {
    setNavItemButtonsEvents();

    const params = new URLSearchParams(document.location.search);
    let category = params.get("category");

    if (!category) category = defaultCategory;

    loadCategory(imagesNamesList, imagesLoaded, category, imagesPath);
    protectImages();
  }

  function loadCategory(
    imagesNamesList,
    imagesLoaded,
    categoryName,
    imagesPath
  ) {
    if (categoriesInitialized.some((category) => category === categoryName))
      return true;

    $(".loader").css("display", "flex");

    const newCategory = { category: categoryName, images: [] };

    categoriesInitialized.push(categoryName);

    findDataImagesByCategory(imagesNamesList, categoryName).forEach(function (
      imageData
    ) {
      const image = new Image();
      image.src = imagesPath + imageData.path;

      const imageLoaded = {
        image: image,
        url: imageData.url,
        src: imageData.src,
      };

      image.onload = () => {
        increaseImagesCounterByCategory(categoryName);
      };

      newCategory.images.splice(
        Math.floor(
          Math.random(0, newCategory.images.length) *
            (newCategory.images.length + 1)
        ),
        0,
        imageLoaded
      );
    });
    imagesLoaded.push(newCategory);
    return false;
  }

  function findDataImagesByCategory(dataImagesList, categoryName) {
    return Object.entries(dataImagesList).find((category) => {
      return category[0] === categoryName;
    })[1];
  }

  function findLoadedImagesByCategory(loadedImagesList, categoryName) {
    return loadedImagesList.find((category) => {
      return Object.values(category)[0] === categoryName;
    }).images;
  }

  function getAmountLoadedImagesByCategory(loadedImagesList, categoryName) {
    return findLoadedImagesByCategory(loadedImagesList, categoryName).length;
  }

  function insertImagesToGallery(gallery, categoryName, imagesLoaded) {
    gallery.html("");

    let delay = 500;
    let imagePosition = 1;

    findLoadedImagesByCategory(imagesLoaded, categoryName).forEach(
      (imageLoaded) => {
        let htmlElement;
        const imageHtml = imageLoaded.image.outerHTML;
        const maxImagePositionWithAnimation = 4;

        const actualDelay = new Number(delay);

        if (hasVideoUrl(categoryName)) {
          htmlElement = $(
            `<div class="img-container">
                        <div class="video" style="display: none">
                        <iframe
                            src="${imageLoaded.src}"
                            data-url="${imageLoaded.url}"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer;
                                autoplay;
                                clipboard-write;
                                encrypted-media;
                                gyroscope;
                                picture-in-picture"
                            allowfullscreen>
                        </iframe>
                    </div>
                        <div style="display: flex; position: relative">
                            ${imageHtml}
                            <div class="play-overlay"></div>
                        </div>
                    </div>`
          );

          htmlElement.click(function (e) {
            const iframe = $(this).find("iframe");

            if (window.innerWidth >= breakpointWidth) {
              if ($(this).find(".video").css("display") === "none")
                $(this).find(".video").css("display", "flex");
              else if (
                e.pageX < iframe.position().left ||
                e.pageX > iframe.position().left + iframe.width()
              ) {
                $(this).find(".video").css("display", "none");
                stopVideo(htmlElement[0]);
              }
            } else {
              window.open(iframe.data("url"), "_blank");
            }
          });
        } else {
          htmlElement = $(
            `<div class="img-container">
                        <div style="display: flex">
                            ${imageHtml}
                        </div>
                    </div>`
          );
        }

        htmlElement.find("img").addClass("img");

        if (imagePosition < maxImagePositionWithAnimation) delay += delay;
        if (imagePosition === maxImagePositionWithAnimation)
          delay += delay - delay * 0.25;
        imagePosition++;
        htmlElement.css("display", "none");
        htmlElement.ready(function () {
          htmlElement.fadeIn(parseInt(actualDelay));
        });

        htmlElement.find(".img-container > div").prepend(imageLoaded.image);
        gallery.append(htmlElement);
      }
    );

    $(".loader").css("display", "none");
  }

  function hasVideoUrl(category) {
    return category === Categories.Film;
  }

  function setNavItemButtonsEvents() {
    $(".btn-category").each(function () {
      $(this).click(() => {
        const categoryWasLoaded = loadCategory(
          imagesNamesList,
          imagesLoaded,
          $(this).data("category"),
          imagesPath
        );

        if (window.innerWidth <= breakpointWidth) closeNav();

        if (categoryWasLoaded)
          insertImagesToGallery(
            $(".gallery"),
            $(this).data("category"),
            imagesLoaded
          );
      });
    });
  }

  function increaseImagesCounterByCategory(category) {
    imagesCounterByCategory.increaseCounter(category);
  }

  function isImagesCounterByCategoryMax(category) {
    return (
      imagesCounterByCategory[category] >=
      getAmountLoadedImagesByCategory(imagesLoaded, category)
    );
  }

  function protectImages() {
    $(".gallery").bind("contextmenu", function (e) {
      e.preventDefault();
    });
  }

  function closeNav() {
    $(".nav").animate(
      {
        right: "-600px",
      },
      "slow"
    );
    $(".btn-open").fadeIn();
    $("html").css({ "overflow-y": "initial" });
  }

  function stopVideo(element) {
    const iframe = element.querySelector("iframe");
    const video = element.querySelector("video");
    if (iframe) {
      const iframeSrc = iframe.src;
      iframe.src = iframeSrc;
    }
    if (video) {
      video.pause();
    }
  }

  imagesNamesList = data;
  initialize();
});
